import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as Sentry from '@sentry/react';
import reportWebVitals from './reportWebVitals';
import mixpanel from 'mixpanel-browser';

const mixpanel_token = process.env.REACT_APP_MIXPANEL_TOKEN;
const build_env = process.env.REACT_APP_ENV;

if (mixpanel_token && build_env !== 'development') {
  mixpanel.init(mixpanel_token, { ignore_dnt: true });
}

Sentry.init({
  dsn: 'https://741d762eaf8195ade0aa63e0ab62b948@sentry.talview.org/172',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  release: process.env.VERSION || 'dev',
  tracesSampleRate: process.env.NODE_ENV !== 'production' ? 1.0 : 0.5,
  environment: process.env.APP_ENV || process.env.NODE_ENV || 'internal',
  enabled: process.env.NODE_ENV !== 'development',
  replaysSessionSampleRate: process.env.NODE_ENV !== 'production' ? 1.0 : 0.5,
  replaysOnErrorSampleRate: 1.0,
  normalizeDepth: 10,
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

//env update
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
